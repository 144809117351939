<template>
  <div class="admin-content-panel py-lg-15 d-flex flex-column-fluid align-items-start container-xxl">
    <div class="content flex-row-fluid" id="kt_content">
      
      <div class="card mb-5 mb-xl-8">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="btn btn-link fw-bolder btn-color-gray-900 fs-1">Visit Details</span>
          </h3>
          
          <span class="admin-close-card-icon" @click="$router.go(-1)">
            <span class="svg-icon svg-icon-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect></svg></span>
          </span>
        </div>
        <div class="card-body py-3">
          
          
          
          
          
          
          
          
          <div class="card mb-5 mb-xl-10" v-if="visitorDetail && visitDetail">
            <div class="card-body pt-9 pb-0">
              <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                <div class="me-7 mb-4">
                  <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                    <img src="../assets/metronic-ui-kit/avatars/avatar.svg" alt="image">
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-center mb-2">
                        <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{{visitDetail.formattedName}}</a>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap flex-stack">
                    <div class="d-flex flex-column flex-grow-1 pe-8">
                      <div class="d-flex flex-wrap">
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div class="d-flex align-items-center">
                            <div class="fs-2 fw-bolder">{{visitorDetail.email || '-'}}</div>
                          </div>
                          <div class="fw-bold fs-6 text-gray-400">Email Address</div>
                        </div>
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div class="d-flex align-items-center">
                            <div class="fs-2 fw-bolder">{{visitorDetail.phoneNumber}}</div>
                          </div>
                          <div class="fw-bold fs-6 text-gray-400">Phone Number</div>
                        </div>
                      </div>
                    </div>
  
                  </div>
                </div>
              </div>
  
            </div>
          </div>
          
          
          <div class="card mb-5 mb-xl-10" v-if="visitDetail && propertyDetail">
            <div class="card-body pt-9 pb-0">
              <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                <div class="me-7 mb-4">
                  <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                    <img src="../assets/metronic-ui-kit/avatars/avatar-home.png" alt="image">
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-center mb-2">
                        <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{{formatPropertyAddress(propertyDetail)}}</a>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap flex-stack">
                    <div class="d-flex flex-column flex-grow-1 pe-8">
                      <div class="d-flex flex-wrap">
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div class="d-flex align-items-center">
                            <div class="fs-2 fw-bolder">{{visitDetail.visitTimestamp.toDate().toLocaleString()}}</div>
                          </div>
                          <div class="fw-bold fs-6 text-gray-400">Visit Date</div>
                        </div>
                      </div>
                    </div>
  
                  </div>
                </div>
              </div>
  
            </div>
          </div>
          
          
          
          <div class="row justify-content-md-center" v-if="visitDetail">
            <div class="col-md-9 col-xxl-8">
              <div class="card card-dashed shadow-sm mb-9">
                  <div class="card-header">
                      <h3 class="card-title fs-2">{{questionaireDetail.title}}</h3>
                  </div>
                  <div class="card-body" v-if="questionaireDetail">
                      <p class="fs-5">{{questionaireDetail.preamble}}</p>
                      
                      <ul class="fs-5">
                        <li v-for="(question, index) in questions">
                          {{question.question}}
                        </li>
                      </ul>
                      
                      <p class="fs-5">{{questionaireDetail.postamble}}</p>
                  </div>
                  <div class="card-footer">
                      <div class="mb-6">
                        <img v-bind:src="getSignatureImageData(visitDetail)" height="90" />
                      </div>
                      <p>{{visitDetail.formattedName}}<br/>Signed on {{visitDetail.visitTimestamp.toDate().toLocaleString()}}</p>
                  </div>
              </div>
            </div>
          </div>
          
          
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { getFirestore, collection, getDoc, getDocs, doc, query, limit, orderBy, where, addDoc, setDoc, Timestamp, Bytes } from "firebase/firestore"
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'AdminVisit',
  data() {
    return { 
      visitDetail: null,
      propertyDetail: null,
      questions: null,
      visitorDetail: null,
      questionaireDetail: {}
    }
  },
  methods: {
    formatPropertyAddress(property) {
      if (!property.address) {
        return "n/a";
      }
      
      let street = property.address.street;
      
      if (property.address.street1) {
        street = property.address.street1;
      }
      
      if (property.address.street2) {
        street += " " + property.address.street2;
      }
    
      if (property.address.locality) {
        street += ", " + property.address.locality;
      }
      
      return street;
    },
    getSignatureImageData(visitDetail) {
      return 'data:image/png;base64,' + visitDetail.signatureImageData.toBase64();
    },
    async init(propertyUid, visitUid) {
      const db = getFirestore();
      const vm = this;
  
      const docRefProperty = doc(db, "propertyListings", propertyUid);
      const docSnapProperty = await getDoc(docRefProperty);
      
      if (docSnapProperty.exists()) {
        vm.propertyDetail = docSnapProperty.data(); 
      }
      
      const docRefVisit = doc(db, "propertyListings", propertyUid, "visits", visitUid);
      const docSnapVisit = await getDoc(docRefVisit);
      
      if (docSnapVisit.exists()) {
        vm.visitDetail = docSnapVisit.data(); 
        
        const docRefQuestionaire = doc(db, vm.visitDetail.questionnaire.path);
        const docSnapQuestionaire = await getDoc(docRefQuestionaire);
        
        if (docSnapQuestionaire.exists()) {
          vm.questionaireDetail = docSnapQuestionaire.data();
        }
        
        const docRefVisitor = doc(db, vm.visitDetail.visitor.path);
        const docSnapVisitor = await getDoc(docRefVisitor);
        
        if (docSnapVisitor.exists()) {
          vm.visitorDetail = docSnapVisitor.data();
        }
        
        const docRefQuestions = collection(db, "questionnaires", docSnapQuestionaire.id, "questions");
        const docSnapQuestions = await getDocs(docRefQuestions);
        
        vm.questions = [];
        
        docSnapQuestions.forEach((question) => {
          vm.questions.push(question.data());
        });        
      }
    }
  },
  props: ['propertyUid', 'visitUid'],
  async created() {
    const vm = this;
    
    vm.init(vm.propertyUid, vm.visitUid);
  }
}
</script>