<template></template>

<script>
export default {
  name: 'TrackVisibility',
  created() {
	function checkElementIsVisible(element) {
		var minVisibleArea = 1;
		var visibleArea = getElementVisibleArea(element);
	
		var visible = visibleArea.visibleArea >= minVisibleArea || visibleArea.areaOnViewport >= minVisibleArea;
	
		// quit immediately if not visible, so we can avoid calling the expensive getComputedStyle function
		if (!visible) {
			return false;
		}
	
		// we need to check computed style as well
		var computedStyle = window.getComputedStyle(element);
		var isVisible = visible && parseFloat(computedStyle.opacity) !== 0 && computedStyle.visibility !== 'hidden';
		
		return {
			'isVisible': isVisible,
			'visibleArea': visibleArea.visibleArea,
			'areaOnViewport': visibleArea.areaOnViewport
		}
	}
	
	function getElementVisibleArea(element) {
		// covers the display:none - on parents as well
		if (element.offsetHeight === 0 || element.offsetWidth === 0) {
			return false;
			}
	
		var width = window.innerWidth;
		var height = window.innerHeight;
		
		var viewportRect = {
			top: 0,
			right: width,
			bottom: height,
			left: 0,
			width: width,
			height: height
		};
	
		var elementRect = element.getBoundingClientRect();
		
		var top = Math.max(elementRect.top, viewportRect.top);
		var left = Math.max(elementRect.left, viewportRect.left);
		
		var right = Math.max(Math.min(elementRect.right, viewportRect.right), left);
		var bottom = Math.max(Math.min(elementRect.bottom, viewportRect.bottom), top);
		
		var overlappingRect = {
			top: top,
			right: right,
			bottom: bottom,
			left: left,
			width: right - left,
			height: bottom - top
		};
	
		var viewportArea = viewportRect.width * viewportRect.height;
		var elementArea = elementRect.width * elementRect.height;
		var overlappingArea = overlappingRect.width * overlappingRect.height;
	
		var visibleArea = Math.round((overlappingArea / elementArea) * 100);
		var areaOnViewport = Math.round((overlappingArea / viewportArea) * 100);
	
		return {
			'visibleArea': visibleArea,
			'areaOnViewport': areaOnViewport
		}
	}
	
	
	window.setInterval(() => {
		document.querySelectorAll('[track-visibility]').forEach((element) => {
			
			var elementVisibleInformation = checkElementIsVisible(element);
			
			if (elementVisibleInformation.isVisible) {
				element.classList.add('track-visibility--is-visible');
				element.classList.remove('track-visibility--not-visible');
			} else {
				element.classList.add('track-visibility--not-visible');
				element.classList.remove('track-visibility--is-visible');
			}
			
			if (elementVisibleInformation.visibleArea > 80) {
				element.classList.add('track-visibility--full');	
			} else {
				element.classList.remove('track-visibility--full');
			}

			if (elementVisibleInformation.visibleArea > 50) {
				element.classList.add('track-visibility--medium');	
			} else {
				element.classList.remove('track-visibility--medium');
			}
			
			if (elementVisibleInformation.visibleArea > 20) {
				element.classList.add('track-visibility--small');	
			} else {
				element.classList.remove('track-visibility--small');
			}
		});
	}, 500);
  }
}
</script>