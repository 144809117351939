<template>
  <AdminPortalPanelsCollapser></AdminPortalPanelsCollapser>
  
  <div class="admin-root">
  
  <div class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <div id="kt_header" class="header align-items-stretch" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
          <div class="container-xxl d-flex align-items-center">
            <div class="d-flex topbar align-items-center d-lg-none ms-n2 me-3" title="Show aside menu">
              <div class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px" id="kt_header_menu_mobile_toggle">
                <span class="svg-icon svg-icon-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                    <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
                  </svg>
                </span>
              </div>
            </div>
            
            <div class="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
              <a href="../../demo2/dist/index.html">
                <img alt="Logo" src="../assets/logo-white.png" class="logo-default h-25px" />
                <img alt="Logo" src="../assets/logo-white.png" class="logo-sticky h-25px" />
              </a>
            </div>
            
            <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
              <div class="d-flex align-items-stretch" id="kt_header_nav">
                <div class="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
                  <div class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
                    
                    
                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-lg-1">
                      <router-link to="/admin/agents" v-slot="{ href, route, navigate, isActive, isExactActive }" custom>
                        <a aria-current="page" :href="href" @click="navigate" class="menu-link py-3" :class="[isActive && 'active', isExactActive && 'active']"
                          ><span class="menu-title">Agents</span></a>
                      </router-link>
                    </div>
                    
                    <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-lg-1">
                      <router-link to="/admin/properties" v-slot="{ href, route, navigate, isActive, isExactActive }" custom>
                        <a aria-current="page" :href="href" @click="navigate" class="menu-link py-3" :class="[isActive && 'active', isExactActive && 'active']"
                          ><span class="menu-title">Open Houses</span></a>
                      </router-link>
                    </div>
                    
                    
                    
                    
                    


                    
                    
                  </div>
                </div>
              </div>
              
              
              
              
              
            </div>
          </div>
        </div>
        
        
        
        
        
        
        <div class="admin-main-container">
          <router-view></router-view>
        </div>
        

        
        
        
        
        
        <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
          <div class="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div class="text-dark order-2 order-md-1">
              <span class="text-muted fw-bold me-1">2022©</span>
              <a href="https://entrysentry.com/" target="_blank" class="text-gray-800 text-hover-primary">EntrySentry</a>
            </div>
            <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
              <li class="menu-item">
                <a href="https://entrysentry.com/" target="_blank" class="menu-link px-2">About</a>
              </li>
              <li class="menu-item">
                <a href="https://entrysentry.com/" target="_blank" class="menu-link px-2">Support</a>
              </li>
              <li class="menu-item">
                <a href="https://entrysentry.com/" target="_blank" class="menu-link px-2">Purchase</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
    
  
  </div>
  
</template>

<script>
import AdminPortalPanelsCollapser from '@/components/AdminPortalPanelsCollapser.vue'

import { getAuth, signOut, setCustomUserClaims, signInAnonymously, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth"
import { getFirestore, collection, getDoc, getDocs, doc, query, limit, orderBy, where, addDoc, setDoc, Timestamp, Bytes } from "firebase/firestore"
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'AdminMain',
  data() {
    return { 
      agentsList: null,
    }
  },
  components: {
    AdminPortalPanelsCollapser
  },
  methods: {
    async init() {
      const auth = getAuth();
      const user = auth.currentUser;
      const db = getFirestore();
      const vm = this;
  
      if (!user || user.isAnonymous || !user.email) {
        this.router.push('/admin/login');
        return false;
      }
      
      const userIdToken = await user.getIdTokenResult();
      
      if (!userIdToken || !userIdToken.claims || !userIdToken.claims.admin) {
        signOut(auth).then(() => {
          vm.router.push('/admin/login');
        });
        
        return false;
      }
      
    }
  },
  async created() {
    const vm = this;
    const auth = getAuth();
    
    vm.router = useRouter();
    
    if (vm.router.currentRoute.value.path === '/admin') {
      return vm.router.push('/admin/agents');
    }
    
    onAuthStateChanged(auth, (user) => {
      // firebase auth is initialized now
      vm.init();
    });
    
  }
}
</script>