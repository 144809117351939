<template>
  <TrackVisibility />
  
  <div class="es_property_listing" :class="{'es_loading': !propertyDetails || loadingData}">
    <section class="es_loading_hero" :class="{'es_collapse': propertyDetails && !loadingData, 'es_fullscreen': showWelcomeMessage}" track-visibility>
      <img src="../assets/logo-white-big.png" class="es_logo" />
      
      <h2 class="es_h2" v-if="!propertyDetails || loadingData">Creating a secure<br/>Open House</h2>
      <h2 class="es_h2" v-if="propertyDetails && !loadingData">Welcome to the<br/>Open House</h2>      
      
      <h5 class="es_h5">
        Track, confirm and document<br/>who's entering homes.
      </h5>
      
      <h3 class="es_h3" v-if="propertyDetails && !loadingData">📍&nbsp;{{getStreetName()}}, {{propertyDetails.address.locality}}</h3>
      
      <img src="../assets/unlocked.png" v-if="showWelcomeMessage" class="es_unlocked" />
          
      <h3 class="es_h3 es_unlocked_message" v-if="showWelcomeMessage">
        You can enter the property now
      </h3>
      
      <div class="es_loader" :class="{'es_hide': !loadingData}">
        <Spinner message="Loading data..." />
      </div>
      
      <div class="es_error" v-show="errorMessage" :class="{'es_active': errorMessage}">
        <span v-if="errorMessage"><b class="es_b">⚠️</b><br/>{{errorMessage}}</span>
      </div>

      <div class="es_access_code_panel es_active" v-if="showWelcomeMessage && showAccessCode && propertyDetails.accessCode">
        <h3 class="es_h3">Access Code</h3>
        <span class="es_access_code">{{propertyDetails.accessCode}}</span>
      </div>
    </section>
    
    <section class="es_hero_panel" v-if="propertyDetails && !loadingData" :class="{'es_visible': scrolled && !showWelcomeMessage}">
      <h3 class="es_h3">{{getStreetName()}}, {{propertyDetails.address.locality}}</h3>
    </section>
    
    <section class="es_visitor_details" v-if="propertyDetails && !loadingData && !showWelcomeMessage">
      <h5 class="es_h5">      
        Please fill in the fields bellow.
        
        <p class="es_disclaimer">We do not sell or use your personal information for any other purposes.</p>
      </h5>
      
      <div id="user_data_fullname" class="es_form_input es_first es_odd es_active" :class="{'es_invalid': checkInvalid('fullname')}">
        <label class="es_label">
          <b class="es_b" v-if="!invalidFields['fullname']">Full name</b>
          <b v-if="invalidFields['fullname']" class="es_b es_invalid_note">{{invalidFields['fullname']}}</b>
          
          <input type="text" id="name" name="name" class="es_input" v-model="visitor.fullname" @blur="validateProperty('fullname')" placeholder="Enter your full name..." />
        </label>
      </div>

      <div id="user_data_email" class="es_form_input es_even" :class="{'es_invalid': checkInvalid('email')}">
        <label class="es_label">
          <b class="es_b" v-if="!invalidFields['email']">Email address</b>
          <b v-if="invalidFields['email']" class="es_b es_invalid_note">{{invalidFields['email']}}</b>
          
          <input type="email" id="email" name="email" class="es_input" v-model="visitor.email"  @blur="validateProperty('email')" placeholder="Enter your email address..." />
          <small class="es_required_note">Your email address and other details will only be used to send you and the open house agent a copy of this agreement. We will not use it for any other purpose.</small>
        </label>
      </div>
      
      <div id="user_data_phone" class="es_form_input es_odd" :class="{'es_invalid': checkInvalid('phone')}">
        <label class="es_label">
          <b class="es_b" v-if="!invalidFields['phone']">Phone number</b>
          <b v-if="invalidFields['phone']" class="es_b es_invalid_note">{{invalidFields['phone']}}</b>
          
          <input type="phone" id="phone" class="es_input" name="phone" v-model="visitor.phone"  @blur="validateProperty('phone')"  placeholder="Enter your phone number..." />
        </label>
      </div>
            
      <div id="user_data_type" class="es_form_input es_odd" :class="{'es_invalid': checkInvalid('visitType')}">
        <label class="es_label">
          <b class="es_b" v-if="!invalidFields['visitType']">Visit Type</b>
          <b v-if="invalidFields['visitType']" class="es_b es_invalid_note">{{invalidFields['visitType']}}</b>
          
          <div class="es_btn_group">
            <a class="es_btn" :class="{'es_active': visitor.visitType === 'buyer'}" @click="visitor.visitType = 'buyer'" >Buyer</a>
            <a class="es_btn" :class="{'es_active': visitor.visitType === 'agent'}" @click="visitor.visitType = 'agent'">Agent</a>
            <a class="es_btn" :class="{'es_active': visitor.visitType === 'other'}" @click="visitor.visitType = 'other'">Other</a>
          </div>
          
          <small class="es_required_note">Are you a Buyer or an Agent?</small>
        </label>
      </div>
      
      <div id="user_data_typeOther" class="es_form_input es_even" v-if="visitor.visitType === 'other'">
        <label class="es_label">
          <b class="es_b" v-if="!invalidFields['typeOther']">Visit Purpose</b>
          <b v-if="invalidFields['typeOther']" class="es_b es_invalid_note">{{invalidFields['typeOther']}}</b>
          
          <input type="text" id="typeOther" class="es_input" name="typeOther" v-model="visitor.visitTypeOther"  @blur="validateProperty('typeOther')" placeholder="Enter the purpose of visit..." />
        </label>
      </div>
      
      <div id="user_data_brokerage" class="es_form_input es_odd" :class="{'es_invalid': checkInvalid('brokerageName')}" v-if="visitor.visitType === 'agent'">
        <label class="es_label">
          <b class="es_b" v-if="!invalidFields['brokerageName']">Brokerage Name</b>
          <b v-if="invalidFields['brokerageName']" class="es_b es_invalid_note">{{invalidFields['brokerageName']}}</b>
          
          <input type="text" id="brokerageName" class="es_input" name="brokerageName" v-model="visitor.brokerageName"  @blur="validateProperty('brokerageName')"  placeholder="Enter brokerage name..." />
        </label>
      </div>  
            
      <div id="user_data_license" class="es_form_input es_odd" :class="{'es_invalid': checkInvalid('licenseNumber')}" v-if="visitor.visitType === 'agent'">
        <label class="es_label">
          <b class="es_b" v-if="!invalidFields['licenseNumber']">License Number</b>
          <b v-if="invalidFields['licenseNumber']" class="es_b es_invalid_note">{{invalidFields['licenseNumber']}}</b>
          
          <input type="text" id="licenseNumber" class="es_input" name="licenseNumber" v-model="visitor.licenseNumber"  @blur="validateProperty('licenseNumber')"  placeholder="Enter license number..." />
        </label>
      </div>  
            
      <div id="user_data_visitors" class="es_form_input es_odd" :class="{'es_invalid': checkInvalid('numberOfVisitors')}" v-if="visitor.visitType === 'agent'">
        <label class="es_label">
          <b class="es_b" v-if="!invalidFields['numberOfVisitors']">Number of Visitors</b>
          <b v-if="invalidFields['numberOfVisitors']" class="es_b es_invalid_note">{{invalidFields['numberOfVisitors']}}</b>
          
          <input type="number" id="numberOfVisitors" class="es_input" name="numberOfVisitors" v-model="visitor.numberOfVisitors"  @blur="validateProperty('numberOfVisitors')"  placeholder="Enter number of visitors..." />
        </label>
      </div>  
      
      <div id="user_data_representing_agent" class="es_form_input es_even" v-if="visitor.visitType === 'buyer'">
        <label class="es_label">
          <b class="es_b">Representing Agent</b>
          
          <input type="text" id="representingAgent" class="es_input" name="representingAgent" v-model="visitor.representingAgent"  @blur="validateProperty('representingAgent')" placeholder="Enter your representing agent..." />
          <small class="es_required_note">This field is optional</small>
        </label>
      </div> 
            
      <div id="user_data_postalCode" class="es_form_input es_even">
        <label class="es_label">
          <b class="es_b">Zipcode</b>
          
          <input type="text" id="postalCode" class="es_input" name="postalCode" v-model="visitor.postalCode"  @blur="validateProperty('postalCode')" placeholder="Enter your zipcode..." />
          <small class="es_required_note">This field is optional</small>
        </label>
      </div>      
            
      <div id="user_data_postalCode" class="es_form_input es_even es_last">
        <label class="es_label">
          <b class="es_b" v-if="!invalidFields['agreeWithTerms']">I Agree to the <a href="https://entrysentry.com/privacy" target="_blank">Privacy Policy</a><br/> and <a href="https://entrysentry.com/terms" target="_blank">Terms of Service</a></b>
          <b v-if="invalidFields['agreeWithTerms']" class="es_b es_invalid_note">Please agree to the <a href="https://entrysentry.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://entrysentry.com/terms" target="_blank">Terms of Service</a></b>
          
          <a class="es_btn" :class="{'es_active': visitor.agreeWithTerms === true}" @click="visitor.agreeWithTerms = true" >I Agree</a>
        </label>
      </div>      
    </section>
    
    <section class="es_questionnaire_details" v-if="questionnaire && !showWelcomeMessage">
      <h2 class="es_h2">{{questionnaire.title}}</h2>
      <h5 class="es_preamble" v-if="questionnaire.preamble">{{questionnaire.preamble}}</h5>
      
      <div class="es_form_input" :class="[{'invalid': getAnswer(index) === undefined, 'odd': index % 2 === 0, 'last': index === (questions.length - 1)}, 'question_' + index]" v-for="(question, index) in questions">
        <h2 class="es_h2">{{question.question}}</h2>
        
        <a class="es_btn" :class="{'es_active': getAnswer() === true}" @click="submitAnswer()" v-if="index === (questions.length - 1)">I Agree</a>
        
        <b class="es_invalid_note" v-if="!getAnswer() && showValidationErrorForQuestions && index === (questions.length - 1)">Please confirm your agreement.</b>
      </div>
      
      <h5 class="es_postamble" v-if="questionnaire.postamble">{{questionnaire.postamble}}</h5>
    </section>
    
    <section class="es_user_signature es_form_input" :class="{'es_invalid': !isFormSigned()}" v-if="questionnaire && !showWelcomeMessage">
      <h2 class="es_h2">Your signature</h2>
      <span class="es_clear_icon" @click="clearSignature()" v-if="hasSignature()">×</span>
      
      <canvas class="es_canvas" id="user_signature_canvas"></canvas>
      
      <b class="es_invalid_note" v-if="!isFormSigned() && showValidationErrorForQuestions">Please sign the form.</b>
    </section>
    
    
    <section class="es_submit_form_panel" v-if="questionnaire && !showWelcomeMessage">
      <b class="es_invalid_note" v-if="formSubmitError">{{formSubmitError}}</b>
      
      <a class="es_btn" @click="submitForm()" :class="{'es_disabled': submittingForm}">Enter the house</a>
      
      <p class="es_disclaimer">We do not sell or use your personal information for any other purposes.</p>
    </section>
  </div>
</template>

<style lang="scss">  
  .es_property_listing {
    padding-bottom: 110px;
    
    &.es_loading {
      padding-bottom: 0;
    }
  }
  
  .es_disclaimer {
    text-align: center;
    font-size: 14px;
    width: 75vw;
    margin: 48px auto 0;
    opacity: 0.5;
  }
  
  .es_login_dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.94);
    z-index: 1000;
    color: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: scale(1.2);
    pointer-events: none;
    opacity: 0;
    transition: all 450ms ease-in;
    
    .es_error {
      max-width: 70vw;
      margin: 0 auto;
      text-align: center;
      color: $yellow-color;
      
      b {
        display: block;
      }
    }
    
    .es_close_icon {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: rgba(255,255,255,0.3);
      color: rgba(0,0,0,0.94);
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1001;
      text-align: center;
      line-height: 50px;
      font-size: 34px;
      font-weight: 200;
    }
    
    &.es_active {
      transform: scale(1);
      opacity: 1;
      pointer-events: auto;
    }
  }
  
  .es_btn_group {
    display: flex;
    
    .es_btn {
      margin: 0 !important;
    }
  }
  
  .es_access_code_panel {
    border: 1px solid rgba(255, 255, 255, 0.5);
    width: 80vw;
    margin: 0 auto;
    border-radius: 5px;
    padding: 8px;
    margin-top: 24px;
    
    .es_h3 {
      font-size: 13px !important;
    }
    
    .es_access_code {
      font-size: 38px;
      color: $white-color;
      font-weight: 700;
      text-align: center;
      letter-spacing: 2px;
      display: block;
    }
  }
  
  .es_submit_form_panel {    
    a.es_btn {
      display: inline-block;
      width: 40%;
      max-width: 260px;
      height: 50px;
      margin: 24px 12px 0;
      background-color: $base-color;
      color: $white-color;
      border: 0;
      font-size: 18px;
      border-radius: 2px;
      font-weight: 600;
      text-transform: uppercase;
      width: 80vw;
      margin: 20px auto;
      display: block;
      text-align: center;
      line-height: 52px;
      
      &.es_disabled {
        opacity: 0.4;
        cursor: not-allowed;
        background-color: $black-light-color;
      }
    }
  }
  
  .es_user_signature {
    background-color: rgba(0,0,0,0.05);
    height: 360px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 50px !important;
    
    .es_clear_icon {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      background-color: rgba(0,0,0,0.1);
      color: rgba(0,0,0,0.4);
      position: absolute;
      top: 40px;
      right: 40px;
      z-index: 10;
      text-align: center;
      line-height: 40px;
      font-size: 28px;
      font-weight: 200;
    }
    
    .es_h2 {
      position: absolute;
      bottom: 35%;
      left: 50%;
      width: 78%;
      transform: translateX(-50%);
      pointer-events: none;
      border-top: 2px dashed $base-color;
      text-align: center;
      padding-top: 12px;
      font-weight: 300;
      font-size: 14px;
      color: rgba(0,0,0,0.5);
    }
    
    & > .es_canvas {
      background-color: $white-color;
      width: 100%;
      height: 100%;
    }
    
    .es_invalid_note {
      font-size: 16px;
      text-align: center;
      display: block;
      font-weight: 500;
      max-width: 250px;
      margin: 24px auto 0;
      color: $red-color;
      position: absolute;
      bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .es_questionnaire_details {
    margin-top: 60px;
    
    & > .es_h2 {
      margin: 20px auto;
      font-size: 18px;
      font-weight: 500;
      max-width: 250px;
      text-align: center;
    }
    
    & > .es_postamble,
    & > .es_preamble {
      margin: 20px auto;
      font-size: 18px;
      font-weight: 400;
      margin: 20px;
      line-height: 1.35em;
      font-size: 13px;
      text-align: center;
    }
    
    .es_invalid_note {
      font-size: 16px;
      text-align: center;
      display: block;
      font-weight: 500;
      max-width: 250px;
      margin: 24px auto 0;
      color: $red-color;
    }
  }
  
  .es_visitor_details {
    & > .es_h5 {
      margin: 20px auto;
      font-size: 18px;
      font-weight: 500;
      max-width: 90%;
      text-align: center;
      
      .es_disclaimer {
        margin-top: 8px;
        font-size: 12px;
      }
      
      .es_b {
        display: block;
      }
      
      .es_a {
        font-size: 13px;
        color: $base-color;
        text-decoration: underline;
      }
    }
  }
  
  .es_label {
    display: block;
  }
  
  .es_form_input {
    overflow: hidden;
    text-align: center;
    padding: 14px 26px;
    background-color: rgba(0,0,0,0.05);
    
    &.es_last {
      border-bottom: 1px solid rgba(0,0,0,0.2);
      padding-bottom: 24px;
    }
    
    &.es_first {
      padding-top: 24px;
    }
    
    .es_label > b {
      font-size: 16px;
      text-align: center;
      display: block;
      font-weight: 500;
      max-width: 250px;
      margin: 0 auto 15px;
      
      &.es_invalid_note {
        color: $red-color;
      }
    }
    
    .es_optional_note,
    .es_required_note {
      font-size: 11px;
      color: &black-color;
      display: block;
      text-align: center;
      margin-top: 6px;
      opacity: 0.6;
    }

    .es_required_note_big {
      font-size: 13px;
      color: &black-color;
      display: block;
      text-align: center;
      margin-top: 6px;
      opacity: 0.6;
    }
    
    a.es_btn {
      display: inline-block;
      width: 86%;
      max-width: 260px;
      height: 70px;
      margin: 24px 12px 0;
      border: 1px solid rgba(0,0,0,0.2);
      background-color: $white-color;
      font-size: 18px;
      border-radius: 2px;
      line-height: 72px;
      text-align: center;
      
      &.es_active {
        border: 2px solid $base-color;
        color: $base-color;
      }
    }
    
    &.es_invalid {
      .es_input {
        border: 1px solid $red-color !important;
      }
    }
    
    .es_h2 {
      font-size: 18px;
      font-weight: 600;
      max-width: 80vw;
      margin: 0 auto;
      text-align: center;
      line-height: 1.2em;
    }
    
    .es_input {
      display: block;
      border: 1px solid rgba(0,0,0,0.2);
      width: 100%;
      margin: 5px 0;
      font-size: 18px;
      padding: 14px 20px;
      box-sizing: border-box;
      border-radius: 2px;
      background-color: $white-color;
      outline: 0;
      
      &:focus {
        border: 1px solid $base-color;
      }
    }
          
    
    &.es_active,
    &.es_completed {
      max-height: 100vh;
      opacity: 1;
    }
  }
  
  .es_hero_panel {
    position: fixed;
    top: -70px;
    transition: top 250ms ease-in;
    left: 0;
    height: 60px;
    align-items: center;
    width: 100vw;
    display: flex;
    justify-items: center;
    z-index: 100;
    background-color: $black-light-color;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.14);
    
    &.es_visible {
      top: 0;
    }
    
    .es_h3 {
      color: $white-color;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      width: 100%;
    }
  }

  .es_loading_hero {
    height: 100vh;
    overflow: hidden;
    background-color: $black-light-color;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    transition: height 500ms ease-out;
    
    &.es_collapse {
      height: 38vh;
      
      .es_h2 {
        margin-top: 30px;
      }
      
      .es_h5 {
        display: none;
      }
      
      .es_logo {
        margin: 3vh auto 0;
      }
    }
    
    .es_unlocked {
      margin: 20px auto;
      display: block;
      height: 76px;
    }
    
    &.es_fullscreen {
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      z-index: 100;
    }
    
    .es_logo {
      width: 64vw;
      display: block;
      margin: 8vh auto 0;
      transition: margin 400ms ease-out;
    }
    
    .es_h5 {
      color: $white-color;
      text-align: center;
      font-weight: 300;
      font-size: 19px;
      margin-bottom: 24px;
      max-width: 88vw;
      margin-left: auto;
      margin-right: auto;
      margin-top: -16px;
    }
    
    .es_h2 {
      color: $white-color;
      font-size: 30px;
      font-weight: 600;
      margin: 30px auto;
      text-align: center;
      line-height: 1.2em;
      margin-top: 70px;
    }
    
    .es_h3 {
      color: $white-color;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
    }
    
    .es_welcome,
    .es_error {
      max-width: 200px;
      text-align: center;
      font-size: 18px;
      color: $white-color;
      line-height: 1.3em;
      margin-left: auto;
      margin-right: auto;
      height: 140px;
      opacity: 0;
      transition: all 320ms ease-out 300ms;
      
      &.es_active {
        opacity: 1;
      }
      
      .es_b {
        font-size: 48px;
        display: block;
        margin-bottom: 16px;
      }
    }
    
    .es_welcome {
      width: 320px;
      max-width: 320px;
      padding-top: 6vh;
      height: 300px;
      
      a.es_btn {
        display: block;
        width: 40%;
        max-width: 260px;
        height: 50px;
        margin: 24px 12px 0;
        background-color: $base-color;
        color: $white-color;
        border: 0;
        font-size: 18px;
        border-radius: 2px;
        font-weight: 600;
        text-transform: uppercase;
        width: 80vw;
        margin: 20px auto;
        display: block;
        text-align: center;
        line-height: 52px;
        
        &.es_disabled {
          opacity: 0.4;
          cursor: not-allowed;
          background-color: $black-light-color;
        }
      }
    }
    
    .es_loader {
      pointer-events: none;
      opacity: 1;
      transition: opacity 320ms ease-out, height 100ms ease-out 600ms;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      overflow: hidden;;
      padding-bottom: 20px;
      margin-top: 80px;
      
      &.es_hide {
        opacity: 0;
        height: 0;
        margin: 0;
      }
    }
  }
</style>

<script>
import Spinner from '@/components/Spinner.vue'
import TrackVisibility from '@/components/TrackVisibility.vue'
import SignaturePad from 'signature_pad'

import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, signOut, signInAnonymously, onAuthStateChanged } from "firebase/auth"
import { getFirestore, collection, getDoc, getDocs, doc, query, limit, where, addDoc, setDoc, Timestamp, Bytes } from "firebase/firestore"
import { useRoute } from 'vue-router'

export default {
  name: 'PropertyListing',
  components: {
    Spinner,
    TrackVisibility,
    SignaturePad
  },
  data() {
    return { 
      emailAddress: null,
      password: null,
      loginErrorMessage: null,
      propertyId: null,
      questionnaireId: null,
      loadingData: true,
      errorMessage: null,
      propertyDetails: null,
      questionnaire: null,
      questions: null,
      visitor: {},
      visit: {},
      showAccessCode: false,
      scrolled: false,
      userDetailsFilledIn: false,
      completedFields: {},
      invalidFields: {},
      signaturePad: null,
      showWelcomeMessage: false,
      showValidationErrorForQuestions: false,
      submittingForm: false,
      showLogin: false,
      showSignIn: false,
      formSubmitError: null,
      formSubmitInitiated: false
    }
  },
  methods: {
    async submitForm() {
      this.formSubmitInitiated = true;
      
      if (this.submittingForm) {
        return false;
      }
      
      this.formSubmitError = null;
      this.submittingForm = true;

      const vm = this;
      const auth = getAuth();
      const db = getFirestore();
      const analytics = getAnalytics();

      this.showValidationErrorForQuestions = true;
      
      if (!this.validateUserData(true)) {
        this.submittingForm = false;
        this.scrollToInvalidField();
        return false;
      }
      
      if (!this.visit.answers || this.questions.length !== this.visit.answers.length) {
        this.submittingForm = false;
        this.scrollToInvalidField();
        return false;
      }
      
      if (!this.isFormSigned()) {
        this.submittingForm = false;
        this.scrollToInvalidField();
        return false;
      }
             
      let familyName = this.visitor.fullname.split(' ')[0];
      let givenName = this.visitor.fullname.substring(this.visitor.fullname.indexOf(' ') + 1);
      
      let visitorData = {
        createdTimestamp: Timestamp.fromDate(new Date()),
        email: this.visitor.email,
        familyName: familyName,
        givenName: givenName,
        phoneNumber: this.visitor.phone,
        _version: 0
      }
      
      if (this.visitor.postalCode) {
        visitorData.address = {
          postalCode: this.visitor.postalCode
        }
      }
            
      const visitorRef = await addDoc(collection(db, "visitors"), visitorData);
      
      let visitorId = visitorRef.id;
      var signatureCanvas = document.querySelector("#user_signature_canvas");
      let signatureDateURL = this.cropSignatureCanvas(signatureCanvas);
      let signatureBase64 = signatureDateURL.replace(/^data:image\/(png|jpg);base64,/, "");
      
      let visitData = {
        visitTimestamp: Timestamp.fromDate(new Date()),
        visitor: doc(db, "visitors/" + visitorId),
        visitType: this.visitor.visitType,
        signatureImageData: Bytes.fromBase64String(signatureBase64),
        questionnaire: doc(db, "questionnaires/" + this.questionnaireId),
        formattedName: this.visitor.fullname,
        _version: 0,
        answers: this.visit.answers
      };
      
      if (this.visitor.visitType === 'other') {
        visitData.vistTypeName = this.visitor.visitTypeOther;
      }

      if (this.visitor.visitType === 'buyer' && this.visitor.representingAgent) {
        visitData.representingAgent = this.visitor.representingAgent;
      }

      if (this.visitor.visitType === 'agent') {
        this.showAccessCode = true;
        
        visitData.brokerageName = this.visitor.brokerageName;
        visitData.licenseNumber = this.visitor.licenseNumber;
        visitData.numberOfVisitors = this.visitor.numberOfVisitors;
      }
      
      const visitRef = await addDoc(collection(db, "propertyListings", this.propertyId, "visits"), visitData);
      let formattedAddress = this.getStreetName() + ", " + this.propertyDetails.address.locality;
      
      logEvent(analytics, 'notification_received');
      
      let visitEventData = {
        visitor_name: this.visitor.fullname,
        visitor_email: this.visitor.email,
        visitor_phone: this.visitor.phone,
        visitor_postalCode: this.visitor.postalCode,
        visit_type: this.visitor.visitType,
        property_address: formattedAddress
      };
      
      if (this.visitor.visitType === 'other') {
        visitEventData.visit_type_name = this.visitor.visitTypeOther;
      }
      
      if (this.visitor.visitType === 'agent') {
        visitEventData.visit_agent_brokerage_name = this.visitor.brokerageName;
        visitEventData.visit_agent_license_number = this.visitor.licenseNumber;
        visitEventData.visit_agent_number_of_visitors = this.visitor.numberOfVisitors;
      }
      
      logEvent(analytics, 'property_visit', visitEventData);
      this.storeDataToLocalStorage();
      
      this.showWelcomeMessage = true;
    },
    getStreetName() {
      if (!this.propertyDetails || !this.propertyDetails.address) {
        return "Unknown";
      }
      
      if (this.propertyDetails.address.street1) {
        let streetName = this.propertyDetails.address.street1;
        
        if (this.propertyDetails.address.street2) {
          streetName += " " + this.propertyDetails.address.street2;
        }
        
        return streetName;
      }
      
      return this.propertyDetails.address.street;
    },
    storeDataToLocalStorage() {
      window.localStorage.setItem('ES_VISITOR', JSON.stringify(this.visitor));
    },
    loadDataFromLocalStorage() {
      let dataFromStorage = window.localStorage.getItem('ES_VISITOR');
      let vm = this;
      
      if (dataFromStorage) {
        try {
          let parsedData = JSON.parse(dataFromStorage);
          this.visitor = parsedData;
        } catch(e) {
          console.log(e);
        }
      }
    },
    clearSignature() {
      if (this.signaturePad) {
        this.signaturePad.clear();
      }
    },
    hasSignature() {
      return this.signaturePad && !this.signaturePad.isEmpty();
    },
    isFormSigned() {
      return this.signaturePad && !this.signaturePad.isEmpty();
    },
    scrollToInvalidField() {
      let selector = '.es_form_input.es_invalid';
      
      this.scrollToElement(selector)
    },
    submitAnswer() {
      if (!this.visit.answers) {
        this.visit.answers = [];
      }
      
      let vm = this;
      
      for (let i = 0; i< vm.questions.length; i++) {
        vm.visit.answers[i] = true;
      }
      
      this.scrollToEnd();
    },
    scrollToEnd() {
      window.setTimeout(() => {
        window.scrollTo({
          top: 10000,
          left: 0,
          behavior: 'smooth'
        });
      }, 150);
    },
    scrollToElement(elementSelector, offset, focus) {
      if (document.querySelector(elementSelector)) {
        window.setTimeout(() => {
          window.scrollTo({
            top: document.querySelector(elementSelector).offsetTop - (offset || 60),
            left: 0,
            behavior: 'smooth'
          });
          
          if (focus && document.querySelector(elementSelector).focus) {
            document.querySelector(elementSelector).focus();
          }
        }, 150);
      }
    },
    getAnswer() {
      return this.visit.answers && this.visit.answers[0];
    },
    checkInvalid(property) {
      return this.invalidFields[property];
    },
    validateType(type) {
      return type && type.length > 0;
    },
    validateFullname(name) {
      return name && name.split(' ').length > 1;
    },
    validatePassword(password) {
      return password && password.length >= 8;
    },
    validatePostalCode(postalCode) {
      const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      return re.test(String(postalCode).toLowerCase());
    },
    validatePhone(phone) {
      const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      return re.test(String(phone).toLowerCase());
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateVisitors(visitType, numberOfVisitors) {
      return visitType !== 'agent' || (!isNaN(numberOfVisitors) && numberOfVisitors >= 0);
    },
    validateLicense(visitType, licenseNumber) {
      return visitType !== 'agent' || (licenseNumber && licenseNumber.length > 2);
    },
    validateBrokerage(visitType, brokerageName) {
      return visitType !== 'agent' || (brokerageName && brokerageName.length > 2);
    },
    validateTypeOther(visitType, typeOthers) {
      return visitType !== 'other' || (typeOthers && typeOthers.length > 2);
    },
    validateProperty(property) {
      if (!this.visitor[property]) {
        return false;
      }
      
      this.validateUserData();  
      
      if (this.invalidFields[property]) {
        return false;
      }
    },
    validateUserData(fullValidation) {
      var allValid = true;
      
      if (this.formSubmitInitiated) {
        fullValidation = true;
      }
      
      if (fullValidation && !this.visitor.fullname) {
        this.invalidFields['fullname'] = 'Please enter your full name.';
        allValid = false;
      } else if (this.visitor.fullname && !this.validateFullname(this.visitor.fullname)) {
        this.invalidFields['fullname'] = 'Please enter both your family and your given name.';
        allValid = false;
      } else {
        this.invalidFields['fullname'] = false;
      }
      
      if (fullValidation && !this.visitor.email) {
        this.invalidFields['email'] = 'Please enter your email address.';
        allValid = false;
      } else if (this.visitor.email && !this.validateEmail(this.visitor.email)) {
        this.invalidFields['email'] = 'Please enter a valid email address.';
        allValid = false;
      } else {        
        this.invalidFields['email'] = false;
      }
      
      if (fullValidation && !this.visitor.phone) {
        this.invalidFields['phone'] = 'Please enter your phone number.';
        allValid = false;
      } else if (this.visitor.phone && !this.validatePhone(this.visitor.phone)) {
        this.invalidFields['phone'] = 'Please enter a valid phone number.';
        allValid = false;
      } else {        
        this.invalidFields['phone'] = false;
      }
      
      if (fullValidation && !this.validateType(this.visitor.visitType)) {
        this.invalidFields['type'] = 'Please select the visit type.';
        allValid = false;
      } else {
        this.invalidFields['type'] = false;
      }

      if (fullValidation && !this.visitor.agreeWithTerms) {
        this.invalidFields['agreeWithTerms'] = true;
        allValid = false;
      } else {
        this.invalidFields['agreeWithTerms'] = false;
      }

      if (fullValidation && !this.validateTypeOther(this.visitor.visitType, this.visitor.visitTypeOther)) {
        this.invalidFields['typeOther'] = 'Please enter the purpose of your visit.';
        allValid = false;
      } else {
        this.invalidFields['typeOther'] = false;
      }

      if (fullValidation && !this.validateBrokerage(this.visitor.visitType, this.visitor.brokerageName)) {
        this.invalidFields['brokerageName'] = 'Please enter the brokerage name.';
        allValid = false;
      } else {
        this.invalidFields['brokerageName'] = false;
      }

      if (fullValidation && !this.validateLicense(this.visitor.visitType, this.visitor.licenseNumber)) {
        this.invalidFields['licenseNumber'] = 'Please enter you license number.';
        allValid = false;
      } else {
        this.invalidFields['licenseNumber'] = false;
      }

      if (fullValidation && !this.validateVisitors(this.visitor.visitType, this.visitor.numberOfVisitors)) {
        this.invalidFields['numberOfVisitors'] = 'Please enter number of visitors in your party.';
        allValid = false;
      } else {
        this.invalidFields['numberOfVisitors'] = false;
      }
      
      if (this.visitor.password && !this.validatePassword(this.visitor.visitType, this.visitor.password)) {
        this.invalidFields['password'] = 'Password must be at least 8 characters long.';
        allValid = false;
      } else {
        this.invalidFields['password'] = false;
      }
      
      return allValid;
    },
    initSignaturePad() {
      if (!this.signaturePad) {
        let vm = this;
        
        window.setTimeout(() => {
          var canvas = document.querySelector("#user_signature_canvas");
          
          vm.signaturePad = new SignaturePad(canvas, {
            onEnd: () => {
              vm.$forceUpdate()
            }
          });
          
          var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    
          // This part causes the canvas to be cleared
          canvas.width = canvas.offsetWidth * ratio;
          canvas.height = canvas.offsetHeight * ratio;
          
          canvas.getContext("2d").scale(ratio, ratio);
        }, 500);
      }
    },
    cropSignatureCanvas(canvas) {
      // First duplicate the canvas to not alter the original
      var croppedCanvas = document.createElement('canvas'),
          croppedCtx    = croppedCanvas.getContext('2d');

          croppedCanvas.width  = canvas.width;
          croppedCanvas.height = canvas.height;
          croppedCtx.drawImage(canvas, 0, 0);

      // Next do the actual cropping
      var w         = croppedCanvas.width,
          h         = croppedCanvas.height,
          pix       = {x:[], y:[]},
          imageData = croppedCtx.getImageData(0,0,croppedCanvas.width,croppedCanvas.height),
          x, y, index;

      for (y = 0; y < h; y++) {
          for (x = 0; x < w; x++) {
              index = (y * w + x) * 4;
              if (imageData.data[index+3] > 0) {
                  pix.x.push(x);
                  pix.y.push(y);

              }
          }
      }
      pix.x.sort(function(a,b){return a-b});
      pix.y.sort(function(a,b){return a-b});
      var n = pix.x.length-1;

      w = pix.x[n] - pix.x[0];
      h = pix.y[n] - pix.y[0];
      var cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

      croppedCanvas.width = w;
      croppedCanvas.height = h;
      croppedCtx.putImageData(cut, 0, 0);

      return croppedCanvas.toDataURL();
    },
    async init(propertyId) {
      const vm = this;
      const auth = getAuth();
      const user = auth.currentUser;
      const db = getFirestore();
  
      if (!user) {
        let singInResponse = await signInAnonymously(auth);
        
        if (singInResponse.error) {
          vm.errorMessage = `An error occured while communicating with the server, code: ${error.code}, message: ${error.message}`;
          console.log(vm.errorMessage);
          vm.loadingData = false;
        }    
      }
  
      const docRef = doc(db, "propertyListings", propertyId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        let propertyDetails = docSnap.data(); 
        
        if (propertyDetails.archived) {
          vm.errorMessage = "Property was not found or is archived.";
          vm.loadingData = false;
  
          return false;
        }
        
        vm.propertyDetails = propertyDetails;
        vm.propertyId = docSnap.id;
        vm.loadDataFromLocalStorage();
        
        // set document title
        document.title = `${vm.getStreetName()} | eSentry.net`;
        
        const docRefQuestionnaire = query(collection(db, "questionnaires"), limit(1), where("active", "==", true));
        const docSnapQuestionnaire = await getDocs(docRefQuestionnaire);
        
        docSnapQuestionnaire.forEach(async (document) => {
          const questionnaireId = document.id;
          vm.questionnaire = document.data();
          vm.questionnaireId = document.id;
        
          const docRefQuestions = collection(db, "questionnaires", questionnaireId, "questions");
          const docSnapQuestions = await getDocs(docRefQuestions);
          
          vm.questions = [];
          
          docSnapQuestions.forEach((question) => {
            vm.questions.push(question.data());
          });
          
          vm.loadingData = false;
          vm.initSignaturePad();
        });
      } else {
        vm.errorMessage = "Property was not found or is archived.";
        vm.loadingData = false;
      }
    }
  },  
  async created() {
    const route = useRoute();
    const vm = this;
    const propertyId = route.params.propertyId;
    
    if (!propertyId) {
      vm.errorMessage = "Property was not found or is archived.";
      vm.loadingData = false;
      
      return false;
    }
    
    // check scroll position
    window.setInterval(() => {
      vm.scrolled = window.scrollY > 150;
    }, 250);
    
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      // firebase auth is initialized now
      vm.init(propertyId);
    });
    
  }
}
</script>