<template>
  <div class="admin-content-panel py-lg-15 d-flex flex-column-fluid align-items-start container-xxl">
    <div class="content flex-row-fluid" id="kt_content">
      
      <div class="card mb-5 mb-xl-8">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <router-link class="btn btn-link fw-bolder btn-color-gray-900 fs-1 admin-content-panel-back-link" :to="{ name: 'AdminAgentsAgent', params: { 'agentUid': agentUid } }">Agent Detail</router-link>
          </h3>
          
          <span class="admin-close-card-icon" @click="$router.go(-1)">
            <span class="svg-icon svg-icon-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect></svg></span>
          </span>
        </div>
        <div class="card-body py-3">
          
          
          
          
          
          
          
          
          <div class="card mb-5 mb-xl-10">
            <div class="card-body pt-9 pb-0">
              <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                <div class="me-7 mb-4">
                  <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                    <img src="../assets/metronic-ui-kit/avatars/avatar.svg" alt="image">
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-center mb-2">
                        <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{{agentDetail.name}}</a>
                      </div>
                    </div>
                    <div class="d-flex my-4">
                      <a @click="resetPassword()" class="btn btn-sm btn-light me-2" :data-kt-indicator="performingResetPassword ? 'on' : 'off'">
                        <span class="indicator-label">Reset Password</span>
                        <span class="indicator-progress">Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </a>
                      <a @click="openOpenHouseDialog()" class="btn btn-sm btn-primary me-2">Create Open House</a>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap flex-stack">
                    <div class="d-flex flex-column flex-grow-1 pe-8">
                      <div class="d-flex flex-wrap">
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div class="d-flex align-items-center">
                            <div class="fs-2 fw-bolder">{{'-'}}</div>
                          </div>
                          <div class="fw-bold fs-6 text-gray-400">Open Houses</div>
                        </div>
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div class="d-flex align-items-center">
                            <div class="fs-2 fw-bolder">{{agentDetail.licenseNumber || '-'}}</div>
                          </div>
                          <div class="fw-bold fs-6 text-gray-400">License Number</div>
                        </div>
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div class="d-flex align-items-center">
                            <div class="fs-2 fw-bolder">{{agentDetail.brokerageName || '-'}}</div>
                          </div>
                          <div class="fw-bold fs-6 text-gray-400">Brokerage Name</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          
          
          
          
          
          
          
          
          
          <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div class="card-header cursor-pointer">
              <div class="card-title m-0">
                <h3 class="fw-bolder m-0">Agent Details</h3>
              </div>
            </div>
            
            
            <div class="alert alert-warning d-flex align-items-center p-5 mb-10" v-if="resetPasswordErrorMessage">
              <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                  <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                </svg>
              </span>
              <div class="d-flex flex-column">
                <span>{{resetPasswordErrorMessage}}</span>
              </div>
            </div>
            
            <div class="alert alert-success d-flex align-items-center p-5 mb-10" v-if="resetPasswordSucessfulyCompleted">
              <span class="svg-icon svg-icon-2hx svg-icon-success me-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                  <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                </svg>
              </span>
              <div class="d-flex flex-column">
                <span>Password reset link was successfully send to {{agentDetail.email}}.</span>
              </div>
            </div>
            
            
            <div class="card-body p-9">
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Full Name</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{agentDetail.name}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Email Address</label>
                <div class="col-lg-8 fv-row">
                  <span class="fw-bold text-gray-800 fs-6">{{agentDetail.email}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Contact Phone</label>
                <div class="col-lg-8 d-flex align-items-center">
                  <span class="fw-bolder fs-6 text-gray-800 me-2">{{agentDetail.phoneNumber}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">License Number</label>
                <div class="col-lg-8">
                  <a href="#" class="fw-bold fs-6 text-gray-800 text-hover-primary">{{agentDetail.licenseNumber}}</a>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Brokerage Name</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{agentDetail.brokerageName}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Brokerage License Number</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{agentDetail.brokerageLicenseNumber}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Brokerage ZIP</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{agentDetail.brokeragePostalCode}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Created</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800" v-if="agentDetail.createdTimestamp">{{agentDetail.createdTimestamp.toDate().toLocaleString()}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Agent UID</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{agentDetail.id}}</span>
                </div>
              </div>
              <div class="row mb-7">
                <label class="col-lg-4 fw-bold text-muted">Account UID</label>
                <div class="col-lg-8">
                  <span class="fw-bolder fs-6 text-gray-800">{{agentDetail.uid}}</span>
                </div>
              </div>
            </div>
          </div>
          
          <AdminProperties v-if="agentDetail.uid" :agentUid="agentDetail.uid" :renderAsEmbeded="true" :key="propertiesKey"></AdminProperties>
          
        </div>
      </div>
    </div>
  </div>

  
  <div class="modal fade show" id="kt_modal_new_target" tabindex="-1" v-if="showOpenHouseDialog" style="display: block;" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content rounded">
        <div class="modal-header pb-0 border-0 justify-content-end">
          <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" @click="showOpenHouseDialog = false">
            <span class="svg-icon svg-icon-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
              </svg>
            </span>
          </div>
        </div>
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <form id="kt_modal_new_target_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">
            <div class="mb-13 text-center">
              <h1 class="mb-3">Create Open House</h1>
            </div>
            <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">Seller</span>
              </label>
              <input type="text" class="form-control form-control-solid" placeholder="Enter Seller Name..." v-model="newProperty.seller" @keyup.enter="createProperty()">
            </div>
            <div class="row g-9 mb-8">
              <div class="col-md-6 fv-row fv-plugins-icon-container">
                <label class="required fs-6 fw-bold mb-2">Street</label>
                <input type="text" class="form-control form-control-solid" placeholder="Enter Street Name..." v-model="newProperty.address.street1" @keyup.enter="createProperty()">
              </div>
              <div class="col-md-6 fv-row">
                <label class="fs-6 fw-bold mb-2">Apartment, suite, etc. <small>(optional)</small></label>
                <input type="text" class="form-control form-control-solid" placeholder="Enter Apartment, suite, etc..." name="target_title" v-model="newProperty.address.street2" @keyup.enter="createProperty()">
              </div>
            </div>
            <div class="d-flex flex-column mb-8">
              <label class="fs-6 fw-bold mb-2">City</label>
              <input type="text" class="form-control form-control-solid" placeholder="Enter City..." name="target_title" v-model="newProperty.address.locality" @keyup.enter="createProperty()">
            </div>
            <div class="row g-9 mb-8">
              <div class="col-md-6 fv-row fv-plugins-icon-container">
                <label class="required fs-6 fw-bold mb-2">State</label>
                <input type="text" class="form-control form-control-solid" placeholder="Enter State..." name="target_title"  v-model="newProperty.address.administrativeArea" @keyup.enter="createProperty()">
              </div>
              <div class="col-md-6 fv-row">
                <label class="required fs-6 fw-bold mb-2">ZIP Code</label>
                <input type="text" class="form-control form-control-solid" placeholder="Enter ZIP Code..." name="target_title" v-model="newProperty.address.postalCode" @keyup.enter="createProperty()">
              </div>
            </div>
            <div class="d-flex flex-column mb-8">
              <label class="fs-6 fw-bold mb-2">Country</label>
              <input type="text" class="form-control form-control-solid" placeholder="Enter Country..." name="target_title" v-model="newProperty.address.country" @keyup.enter="createProperty()">
            </div>
            
            <div class="alert alert-warning d-flex align-items-center p-5 mb-10" v-if="createPropertyErrorMessage">
              <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                  <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                </svg>
              </span>
              <div class="d-flex flex-column">
                <span>{{createPropertyErrorMessage}}</span>
              </div>
            </div>
            
            <div class="alert alert-success d-flex align-items-center p-5 mb-10" v-if="propertySucessfulyCreated">
              <span class="svg-icon svg-icon-2hx svg-icon-success me-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                  <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                </svg>
              </span>
              <div class="d-flex flex-column">
                <span>Open House was successfully created.</span>
              </div>
            </div>

            <div class="text-center">
              <button type="reset" id="kt_modal_new_target_cancel" @click="showOpenHouseDialog = false" class="btn btn-light me-3">Cancel</button>
              <span id="kt_modal_new_target_submit" @click="createProperty()" :data-kt-indicator="creatingProperty ? 'on' : 'off'" class="btn btn-primary">
                <span class="indicator-label">Create</span>
                <span class="indicator-progress">Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
              </span>
            </div>
          <div></div></form>
        </div>
      </div>
    </div>
  </div>
  
  <router-view></router-view>
</template>



<script>
import { getAuth, signOut, signInAnonymously, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth"
import { getFirestore, collection, getDoc, getDocs, doc, query, limit, orderBy, where, addDoc, setDoc, Timestamp, Bytes } from "firebase/firestore"
import { useRouter, useRoute } from 'vue-router'
import AdminProperties from '@/components/AdminProperties.vue'

export default {
  name: 'AdminAgent',  
  components: {
    AdminProperties
  },
  data() {
    return { 
      agentUid: null,
      showOpenHouseDialog: false,
      agentDetail: {},
      propertySucessfulyCreated: false,
      creatingProperty: false,
      createPropertyErrorMessage: null,
      newProperty: { address: {} },
      propertiesKey: 0,
      performingResetPassword: false,
      resetPasswordErrorMessage: null,
      resetPasswordSucessfulyCompleted: false
    }
  },
  methods: {
    openOpenHouseDialog() {
      const vm = this;
      
      vm.showOpenHouseDialog = true;
      vm.newProperty =  { address: {} };
      vm.createPropertyErrorMessage = null;
      vm.creatingProperty = false;
      vm.propertySucessfulyCreated = false;
    },
    async resetPassword() {
      const vm = this;
      const auth = getAuth();
      
      vm.performingResetPassword = true;
      
      sendPasswordResetEmail(auth, vm.agentDetail.email)
        .then(() => {
          vm.performingResetPassword = false;
          vm.resetPasswordSucessfulyCompleted = true;
        }) 
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          
          vm.performingResetPassword = false;
          vm.resetPasswordSucessfulyCompleted = false;
          vm.resetPasswordErrorMessage = "An error occured while sending the reset password email: " + errorMessage;
        });
    },
    async createProperty() {
      const vm = this;
      const db = getFirestore();
      
      if (vm.creatingProperty) {
        return false;
      }
      
      vm.creatingProperty = true;
      vm.createPropertyErrorMessage = null;
      
      if (!vm.newProperty.seller || !vm.newProperty.address.street1 || !vm.newProperty.address.street2 || !vm.newProperty.address.locality || !vm.newProperty.address.administrativeArea || !vm.newProperty.address.postalCode || !vm.newProperty.address.country) {
        vm.createPropertyErrorMessage = 'All fields are required';
        vm.creatingProperty = false;
        return false;
      }
      
      vm.newProperty.createdTimestamp = Timestamp.fromDate(new Date());
      vm.newProperty.archived = false;
      vm.newProperty._version = 0;
      vm.newProperty.address._version = 1;
      vm.newProperty.uid = vm.agentDetail.uid;
      
      const newPropertyResponse = await addDoc(collection(db, "propertyListings"), vm.newProperty);
      
      vm.propertySucessfulyCreated = true;
      vm.creatingProperty = false;
      vm.propertiesKey++;
    },
    async init(agentUid) {
      const db = getFirestore();
      const vm = this;
  
      const docRef = doc(db, "agents", agentUid);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        vm.agentDetail = docSnap.data(); 
        vm.agentDetail.id = docSnap.id;
      }
    }
  },
  async created() {
    const vm = this;
    
    const route = useRoute();
    vm.agentUid = route.params.agentUid;
    
    vm.init(vm.agentUid);
  }
}
</script>