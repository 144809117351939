<template>
  <div class="py-lg-15 d-flex flex-column-fluid align-items-start" :class="[ !renderAsEmbeded && 'admin-content-panel container-xxl' ]">
    <div class="content flex-row-fluid" id="kt_content">
      
      <div class="card mb-5 mb-xl-8">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">
              <router-link class="btn btn-link fw-bolder btn-color-gray-900 fs-1" :to="{ name: 'AdminProperties' }">Open Houses</router-link>
            </span>
            <span class="text-muted mt-1 fw-bold fs-7">
              <span v-if="loadingData">Loading data...</span>
              <span v-if="!loadingData">{{propertiesListProcessed.length}} Open Houses found</span>
            </span>
          </h3>
        </div>
        <div class="card-body py-3">
          <div class="table-responsive">
            <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 dataTable">
              <thead>
                <tr>
                  <td><input type="text" class="form-control" v-model.lazy="filter.formattedAddress" placeholder="Address..." /></td>
                  <td><input type="text" class="form-control" v-model.lazy="filter.seller" placeholder="Seller Name..." /></td>
                  <td><input type="text" class="form-control" v-model.lazy="filter.administrativeArea" placeholder="State..." /></td>
                  <td><input type="text" class="form-control" v-model.lazy="filter.postalCode" placeholder="Postal Code..." /></td>
                  <td><input type="text" class="form-control" v-model.lazy="filter.archivedValue" placeholder="Archived..." /></td>
                  <td><input type="text" class="form-control" v-model.lazy="filter.createdTimestampFormatted" placeholder="Created..." /></td>
                </tr>
                
                <tr class="fw-bolder text-muted">
                  <th @click="updateSortBy('formattedAddress')" class="min-w-150px" :class="[sortBy.name === 'formattedAddress' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'formattedAddress' && !sortBy.asc && 'sorting sorting_desc']">Address</th>
                  <th @click="updateSortBy('seller')" class="min-w-140px" :class="[sortBy.name === 'seller' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'seller' && !sortBy.asc && 'sorting sorting_desc']">Seller Name</th>
                  <th @click="updateSortBy('administrativeArea')" class="min-w-120px" :class="[sortBy.name === 'administrativeArea' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'administrativeArea' && !sortBy.asc && 'sorting sorting_desc']">State</th>
                  <th @click="updateSortBy('postalCode')" class="min-w-120px" :class="[sortBy.name === 'postalCode' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'postalCode' && !sortBy.asc && 'sorting sorting_desc']">Postal Code</th>
                  <th @click="updateSortBy('archivedValue')" class="min-w-120px" :class="[sortBy.name === 'archivedValue' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'archivedValue' && !sortBy.asc && 'sorting sorting_desc']">Archived</th>
                  <th @click="updateSortBy('createdTimestampDate')" class="min-w-120px" :class="[sortBy.name === 'createdTimestampDate' && sortBy.asc && 'sorting sorting_asc', sortBy.name === 'createdTimestampDate' && !sortBy.asc && 'sorting sorting_desc']">Created</th>
                </tr>
                
              </thead>
              <tbody>
                <tr v-for="propertyDetail in propertiesListProcessed">
                  <td>
                    <router-link class="btn btn-link btn-color-info btn-active-color-primary fw-bolder text-hover-primary fs-6" :to="{ name: detailPathName, params: { 'propertyUid': propertyDetail.propertyUid } }" append>{{propertyDetail.formattedAddress}}</router-link>
                  </td>
                  <td>
                    <span class="text-dark fw-bolder">{{propertyDetail.seller}}</span>
                  </td>
                  <td>
                    <span class="text-dark">{{propertyDetail.administrativeArea}}</span>
                  </td>
                  <td>
                    <span class="text-dark fw-bolder d-block mb-1 fs-6">{{propertyDetail.postalCode}}</span>
                  </td>
                  <td class="text-dark fs-6">
                    <span class="text-dark">{{propertyDetail.archivedValue}}</span>
                  </td>
                  <td>
                    <span class="text-dark">{{propertyDetail.createdTimestampFormatted}}</span>
                  </td>
                </tr>
                
                <tr v-if="loadingData">
                  <td  colspan="6" class="text-center">Loading data...</td>
                </tr>

                <tr v-if="!loadingData && propertiesListProcessed.length === 0">
                  <td  colspan="6" class="text-center">No open houses found...</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <router-view></router-view>
</template>

<script>
import { getFirestore, collection, getDoc, getDocs, doc, query, limit, orderBy, where, addDoc, setDoc, Timestamp, Bytes } from "firebase/firestore"
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'AdminProperties',
  data() {
    return { 
      propertiesList: [],
      propertiesListProcessed: [],
      renderAsEmbeded: false,
      detailPathName: null,
      sortBy: { name: 'formattedAddress', asc: false },
      filter: {},
      loadingData: false
    }
  },
  watch: {
    filter: {
      handler(newFilters) {
        this.processPropertiesList();
      },
      deep: true
    }
  },
  methods: {
    updateSortBy(name) {
      const vm = this;
      
      if (vm.sortBy.name === name) {
        vm.sortBy.asc = !vm.sortBy.asc;
      } else {
        vm.sortBy.name = name;
        vm.sortBy.asc = false;
      }
      
      vm.processPropertiesList();
    },
    processPropertiesList() {
      const vm = this;
      let processedList = [];
      
      // filter
      vm.propertiesList.forEach(property => {
        let passAllFilters = true;
        
        for (const [key, value] of Object.entries(vm.filter)) {
          if (!property[key] || property[key].toLowerCase().indexOf(value.toLowerCase()) === -1) {
            passAllFilters = false;
          }
        }
        
        if (passAllFilters) {
          processedList.push(property);
        }
      });
      
      //sort
      processedList.sort((entity1, entity2) => {
        let property1 = entity1[vm.sortBy.name];
        let property2 = entity2[vm.sortBy.name];
        
        let result = 0;
        
        if (property1.getTime && property2.getTime) {
          console.log('sort by date');
          result = property1.getTime() - property2.getTime();
        } else if (!isNaN(property1) && !isNaN(property2)) {
          result = property1 - property2;
        } else if (property1.localeCompare && property2.localeCompare) {
          result = property1.localeCompare(property2);
        }
        
        if (vm.sortBy.asc) {
          result *= -1;
        }
        
        return result;
      });
      
      vm.propertiesListProcessed = processedList;
    },
    formatPropertyAddress(property) {
      if (!property.address) {
        return "n/a";
      }
      
      let street = property.address.street;
      
      if (property.address.street1) {
        street = property.address.street1;
      }
      
      if (property.address.street2) {
        street += " " + property.address.street2;
      }

      if (property.address.locality) {
        street += ", " + property.address.locality;
      }
      
      return street;
    },
    async init(agentUid) {
      const db = getFirestore();
      const vm = this;
      
      vm.loadingData = true;
  
      let docRefProperties = query(collection(db, "propertyListings"), limit(1000));
      if (agentUid) {
        docRefProperties = query(collection(db, "propertyListings"), limit(1000), where("uid", "==", agentUid));
      }
      
      const docSnapProperties = await getDocs(docRefProperties);
      
      vm.propertiesList = [];
      docSnapProperties.forEach(property => {
        let propertyData = property.data();
        propertyData.propertyUid = property.id;
        propertyData.agentUid = propertyData.uid;
        propertyData.formattedAddress = vm.formatPropertyAddress(propertyData);
        propertyData.createdTimestampFormatted = propertyData.createdTimestamp.toDate().toLocaleString();
        propertyData.createdTimestampDate = propertyData.createdTimestamp.toDate();
        propertyData.postalCode = propertyData.address.postalCode;
        propertyData.administrativeArea = propertyData.address.administrativeArea;
        propertyData.archivedValue = propertyData.archived ? 'Archived' : 'Active';
        
        vm.propertiesList.push(propertyData);
      });
      
      vm.processPropertiesList();
      vm.loadingData = false;
    }
  },
  props: ['agentUid', 'renderAsEmbeded'],
  async created() {
    const vm = this;
        
    vm.detailPathName = 'AdminAgentsAgentProperty';
    
    let currentPathName = useRouter().currentRoute.value.name || '';
    if (currentPathName.indexOf('AdminProperties') === 0) {
      vm.detailPathName = 'AdminPropertiesProperty';
    }
    
    vm.init(vm.agentUid);
  }
}
</script>