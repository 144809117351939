import { createRouter, createWebHistory } from 'vue-router'
import PropertyListing from '../views/PropertyListing.vue'
import AdminLogin from '../views/AdminLogin.vue'
import AdminMain from '../views/AdminMain.vue'
import AdminAgents from '../views/AdminAgents.vue'
import AdminProperties from '../components/AdminProperties.vue'
import AdminAgent from '../views/AdminAgent.vue'
import AdminProperty from '../components/AdminProperty.vue'
import AdminVisit from '../components/AdminVisit.vue'

const routes = [
  {
    path: '/:propertyId?',
    name: 'PropertyListing',
    component: PropertyListing
  },
  {
    path: '/admin',
    name: 'AdminMain',
    component: AdminMain,
    children: [
      {
        path: 'login',
        name: 'Admin',
        component: AdminLogin
      },
      {
        path: 'agents/',
        name: 'AdminAgents',
        component: AdminAgents,
        children: [
          {
            path: ':agentUid',
            name: 'AdminAgentsAgent',
            props: true,
            component: AdminAgent,
            children: [
              {
                path: ':propertyUid',
                props: true,
                component: AdminProperty,
                name: 'AdminAgentsAgentProperty',
                children: [
                  {
                    path: ':visitUid',
                    props: true,
                    name: 'visitDetail',
                    name: 'AdminAgentsAgentPropertyVisit',
                    component: AdminVisit
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'properties',
        name: 'AdminProperties',
        component: AdminProperties,
        children: [
          {
            path: ':propertyUid',
            props: true,
            component: AdminProperty,
            name: 'AdminPropertiesProperty',
            children: [
              {
                path: ':visitUid',
                name: 'AdminPropertiesPropertyVisit',
                props: true,
                component: AdminVisit
              }
            ]
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
