import "./metronic-ui-kit/assets/plugins/global/plugins.bundle.css";
import "./metronic-ui-kit/assets/css/style.bundle.css";

import { createApp } from 'vue'
import { initializeApp } from "firebase/app";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

const firebaseConfig = {
  apiKey: "AIzaSyCR8cD8toIT2RgGji7bpCbH8q_K7y6SG00",
  authDomain: "entry-sentry-dev.firebaseapp.com",
  projectId: "entry-sentry-dev",
  storageBucket: "entry-sentry-dev.appspot.com",
  messagingSenderId: "494372167884",
  appId: "1:494372167884:web:7c569d33bbcb0601096269",
  measurementId: "G-E58H9G81C6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

createApp(App).use(router).mount('#app')
