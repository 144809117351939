<template>
  
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style="background-image: url(assets/media/illustrations/sigma-1/14.png">
      <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <a class="mb-12">
          <img alt="Logo" src="../assets/logo-big.png" class="h-100px" />
        </a>
        <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" action="#">
            <div class="text-center mb-10">
              <h1 class="text-dark mb-3">Sign In</h1>
            </div>
            <div class="fv-row mb-10">
              <label class="form-label fs-6 fw-bolder text-dark">Email</label>
              <input class="form-control form-control-lg form-control-solid" type="text" name="email" autocomplete="off" v-model="emailAddress" @keyup.enter="loginUser()" />
            </div>
            <div class="fv-row mb-10">
              <div class="d-flex flex-stack mb-2">
                <label class="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
              </div>
              <input class="form-control form-control-lg form-control-solid" type="password" name="password" autocomplete="off" v-model="password" @keyup.enter="loginUser()" />
            </div>
            
            <div class="alert alert-warning d-flex align-items-center p-5 mb-10" v-if="loginErrorMessage">
              <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                  <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                </svg>
              </span>
              <div class="d-flex flex-column">
                <span>{{loginErrorMessage}}</span>
              </div>
            </div>
            
            <div class="text-center">
              <a @click="loginUser()" :data-kt-indicator="submittingForm ? 'on' : 'off'" class="btn btn-lg btn-primary w-100 mb-5">
                <span class="indicator-label">Continue</span>
                <span class="indicator-progress">Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
              </a>
              <div class="text-center text-muted text-uppercase fw-bolder mb-5">or</div>
              <a class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
              <img alt="Logo" src="../assets/metronic-ui-kit/svg/brand-logos/google-icon.svg" class="h-20px me-3" />Continue with Google</a>
              <a class="btn btn-flex flex-center btn-light btn-lg w-100">
              <img alt="Logo" src="../assets/metronic-ui-kit/svg/brand-logos/apple-black.svg" class="h-20px me-3" />Continue with Apple</a>
            </div>
          </form>
        </div>
      </div>
      <div class="d-flex flex-center flex-column-auto p-10">
        <div class="d-flex align-items-center fw-bold fs-6">
          <a href="" class="text-muted text-hover-primary px-2">About</a>
          <a href="" class="text-muted text-hover-primary px-2">Privacy Policy</a>
          <a href="" class="text-muted text-hover-primary px-2">Contact Us</a>
        </div>
      </div>
    </div>
  </div>  
  
</template>

<script>
import { getAuth, signOut, signInAnonymously, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth"
import { getFirestore, collection, getDoc, getDocs, doc, query, limit, where, addDoc, setDoc, Timestamp, Bytes } from "firebase/firestore"
import { useRouter } from 'vue-router'

export default {
  name: 'AdminLogin',
  data() {
    return { 
      emailAddress: null,
      password: null,
      loginErrorMessage: null,
      submittingForm: false,
      router: null
    }
  },
  methods: {
    async loginUser() {
      if (this.submittingForm) {
        return false;
      }
      
      this.loginErrorMessage = null;
      this.submittingForm = true;
      
      let vm = this;
      
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.emailAddress, this.password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          
          vm.submittingForm = false;
          vm.router.push('/admin/agents');
        })
        .catch((error) => {
          vm.submittingForm = false;
          vm.loginErrorMessage = 'The email address and password does not match.';
          
          console.log(`An error occured while communicating with the server, code: ${error.code}, message: ${error.message}`);
        });
    },
    async init() {
      const auth = getAuth();
      const user = auth.currentUser;
      const db = getFirestore();
      const vm = this;
      
      if (!user || user.isAnonymous || !user.email) {
        return false;
      }
      
      const userIdToken = await user.getIdTokenResult();
      
      if (!userIdToken || !userIdToken.claims || !userIdToken.claims.admin) {
        signOut(auth).then(() => {
          vm.router.push('/admin/login');
        });
        
        return false;
      }
      
      vm.router.push('/admin/agents');
    }
  },
  async created() {
    const vm = this;
    const auth = getAuth();
    
    vm.router = useRouter();
    
    onAuthStateChanged(auth, (user) => {
      vm.init();
    });
  }
}
</script>